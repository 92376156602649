import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from '../components/layout'
import PressContentArea from '../templates/press-content-area'
import ogMetaImage from '../../static/entratalogo_1200x630.png'

export default function PressPage({ data, pageContext }) {
  const layoutPieces = data.contentfulPressPage.layoutPieces
  return (
    <Layout header={data?.contentfulBlogPage?.header} footer={data?.contentfulBlogPage?.footer}>
      <PressContentArea layoutPieces={layoutPieces}/>
    </Layout>
  )
}

export const Head = ({data}) => (
  <>
    <meta property="og:title" content={data?.contentfulPressPage?.metadata?.ogMetaTitle ? data?.contentfulPressPage?.metadata?.ogMetaTitle : "Entrata | Press Room"} />
    <meta property="og:description" content={data?.contentfulPressPage?.metadata?.ogMetaDescription ? data?.contentfulPressPage?.metadata?.ogMetaDescription : "All the updates, news, and announcements your heart can handle"} />
    <meta property="og:url" content="https://www.entrata.com/press" />
    <meta name="description" content={data?.contentfulPressPage?.metadata?.metaDescription ? data?.contentfulPressPage?.metadata?.metaDescription : "All the updates, news, and announcements your heart can handle"} />
    <title>Entrata | Press Room</title>
    <meta property="og:image" content={data?.contentfulPressPage?.metadata?.ogMetaImage?.url ? data?.contentfulPressPage?.metadata?.ogMetaImage?.url : ogMetaImage} />
  </>
)

export const query = graphql`
  query PressPage {
    contentfulPressPage {
      metadata {
        metaDescription
        metaTitle
        ogMetaDescription
        ogMetaImage {
          url
        }
        ogMetaTitle
        ogMetaType
      }
      layoutPieces {
        ... on Node {
          internal {
            type
          }
        }
      }
      layoutPieces {
        ... on ContentfulDuplexFeature {
          ...DuplexFeature
        }
        ... on ContentfulGlobalIntro {
          introText
          introTitle
          introButton {
            buttonOutlined
            buttonText
            slug
            fullUrl
          }
        }
        ... on ContentfulPressPosts {
          ...PressFeed
        }
      }
    }
  }
`
