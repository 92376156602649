import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import LinkArrow from '../assets/link-arrow.svg'
import LinkArrowLight from '../assets/link-arrow-light.svg'
import ReportIcon from '../assets/blog-report-icon.svg'
import EventIcon from '../assets/blog-event-icon.svg'
import StudyIcon from '../assets/blog-study-icon.svg'
import LinkIcon from '../assets/link-icon.svg'
import ReactMarkdown from 'react-markdown'
import RichText from '../components/rich-text'
import '../styles/blog-post-feed.css'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { checkSvg } from '../helper-functions'
const contentful = require('contentful')

export default function BlogFeed({ layoutPiece }) {
  const [ post, setPost ] = useState()
  const posts = layoutPiece?.pressPosts
  const bigRichText = {
    richTextData: posts[0]?.bodyContent?.raw ? JSON.parse(posts[0].bodyContent.raw) : null,
    content: post?.items[0].fields.bodyContent ? post?.items[0].fields.bodyContent.content : '',
    assetSize: '50%' // CSS width property
  }

  useEffect(() => {
    const client = contentful.createClient({
      space: process.env.CONTENTFUL_SPACE_ID,
      accessToken: process.env.CONTENTFUL_ACCESS_TOKEN
    })
    const getPost = async (slug) => {
      if (!post) {
        await client.getEntries({
          content_type: 'newsOrBlogPost',
          limit: 1,
          include: 10,
          'fields.slug': slug
        })
        .then((entry) => setPost(entry))
        .catch(console.error)
      } else {
        null
      }
    }
    getPost(posts[0]?.slug)
  }, [])

  const postsPerSlide = 6
  const blogFeedArray = posts.slice(1)
  const numPages = Math.ceil(blogFeedArray.length / postsPerSlide)
  const [startSlide, setStartSlide] = useState(0)
  const [endSlide, setEndSlide] = useState(6)
  const [currentPage, setCurrentPage] = useState(0)
  const GetNextSet = () => {
    if (currentPage < numPages) {
      setStartSlide(startSlide + postsPerSlide)
      setEndSlide(endSlide + postsPerSlide)
      setCurrentPage(currentPage + 1)
    } else {

    }
  }
  const GetPrevSet = () => {
    if (currentPage !== 0) {
      setStartSlide(startSlide - postsPerSlide)
      setEndSlide(endSlide - postsPerSlide)
      setCurrentPage(currentPage - 1)
    } else {

    }
  }
  const bigImage = getImage(posts[0]?.attachments[0])
  return (
    <div className="main-blog-feature">
      <div className="content-cap">
        <div className="blog-feature-header">
          <p>Featured</p>
        </div>

        <div className="big-blog-grid">
          <div className="big-blog-content">
            <h3>{posts[0]?.metaTitle}</h3>
            {
              posts[0]?.bodyContent?.raw ?
                <div className='rich-text big-blog-text'>
                  <div className='big-blog-text-overlay'></div>
                  { post ? <RichText data={bigRichText} /> : '' }
                </div>
              : <ReactMarkdown className="big-blog-text">{posts[0]?.bodyText?.bodyText.split(" ").splice(0,100).join(" ") + "..."}</ReactMarkdown>
            }
            <Link className="cta-link-default dark-cta-link" to={posts[0].slug}>Continue Reading<span className="link-arrow-icon"><LinkArrow /></span></Link>
          </div>
          <div className="big-blog-image">
            <Link to={posts[0].slug}><LinkIcon className="link-icon" /></Link>
            <div className="blog-image-overlay"></div>
            {
              checkSvg(posts[0]?.attachments[0]?.url) ?
                <img src={posts[0]?.attachments[0]?.url} alt={posts[0]?.attachments[0]?.description} className="big-blog-image-file" />
                :
                <GatsbyImage image={bigImage} alt={posts[0]?.attachments[0]?.description} className="big-blog-image-file" />
            }
          </div>
        </div>
        <div className="blog-break"></div>
        <div className="blog-feed-grid">
          {blogFeedArray.slice(startSlide, endSlide).map((blogFeedItem, index) => {
            const littleRichText = {
              richTextData: blogFeedItem?.bodyContent?.raw ? JSON.parse(blogFeedItem.bodyContent.raw) : null
            }
            const gatsbyImage = getImage(blogFeedItem?.attachments[0])
            const image = blogFeedItem?.attachments[0]
            return(
              <div key={index} className="blog-blurb">
                <div className="blurb-image-holder">
                  {blogFeedItem?.attachments ?
                    checkSvg(image?.url) ?
                      <img src={image?.url} alt={image?.description} className="blurb-image-file" />
                      :
                      <GatsbyImage image={gatsbyImage} alt={image?.description} className="blurb-image-file" />
                    : ""
                  }
                </div>
                <p>{blogFeedItem?.title}</p>
                <p className='sub-text'>{blogFeedItem?.previewText}</p>
                <Link className="cta-link-default dark-cta-link" to={blogFeedItem?.slug}>Read More<span className="link-arrow-icon"><LinkArrow /></span></Link>
              </div>
            )
          })}
        </div>
        <div className="blog-feed-nav">
          {currentPage !== 0 ?
            <div className="button-default solid-dark-button blog-feed-button" onClick={(GetPrevSet)}><span className="blog-prev-arrow-icon"><LinkArrowLight /></span>Load Prev</div>
            : ""
          }
          {currentPage !== numPages && numPages !== 1 ?
            <div className="button-default solid-dark-button blog-feed-button" onClick={(GetNextSet)}>Load Next<span className="blog-next-arrow-icon"><LinkArrowLight /></span></div>
            : ""
          }
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
  fragment PressFeed on ContentfulPressPosts {
    pressPosts {
      author
      category
      date
      metaTitle
      slug
      title
      metaDescription {
        metaDescription
      }
      bodyText {
        bodyText
      }
      previewText
      bodyContent {
        raw
        references {
          contentful_id
          url
          description
        }
      }
      attachments {
        gatsbyImageData(width: 800, placeholder: BLURRED)
        description
        url
      }
    }
  }
`